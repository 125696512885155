h4 {
  font-family: 'Segoe UI', bold;
  font-size: 35px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

form {
  display: inline-block;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
}
.text_area {
  align-content: center;
  border-radius: 22px;
  width: 100%;
  height: 48px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 0 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-submit {
  width: 200px;
  height: 50px;
  border-radius: 30px;
  background: #1b8af1;
  border: none;
  font-size: 20px;
  color: #ffffff;
  // box-shadow: 0 4px 8px 0 rgba(76, 31, 255, 0.7), 0 6px 20px 0 rgba(102, 6, 236, 0.9);
  cursor: pointer;
  margin-bottom: 10%;
}
.wrapper {
  background: linear-gradient(160deg, #1b8af1, #b58ded);
}
.login-form {
  background: #ffffff;
  width: 40%;
  border-radius: 22px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  & .ant-form-item-explain-error {
    margin-left: 15px;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
}

.link {
  color: #0e4579;
  font-family: 'Montserrat light';
  font-size: 18px;
  cursor: pointer;
  display: block;
  text-decoration: none;
}
