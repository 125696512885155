.ant-upload-list-item-name {
 color: blueviolet !important;   
}
.anticon-paper-clip {
    color: blueviolet !important;   
}
.ant-upload-list-text {
    float: left;
}
.activeButton{
    border: 1px solid blueviolet;
}