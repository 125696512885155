body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  position: relative;
}

.home {
  // background: linear-gradient(160deg, #1b8af1, #b58ded);
  background: #deeeff;
  height: 100vh;
  width: 100vw;
}

body * {
  font-family: 'Roboto';
  letter-spacing: 0.05rem;
}

h1 {
  font-weight: bold;
}

.ant-message {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.app-table-component {
  width: 100%;
  overflow: auto;
  & .ant-table-cell-row-hover {
    font-weight: 600;
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999ca7 !important;
  border-radius: 32px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999ca7;
}
.ant-table-column-sorters {
  cursor: pointer;
  justify-content: center !important;
  .col-name {
    margin-right: 0.8rem;
  }
  .anticon {
    color: #c0c0c0;
    font-size: 1rem;
    &.active {
      color: #2d2d2d;
    }
  }
}
.row-button {
  width: 100%;
  display: flex;
  column-gap: 1rem;
}

.normal-table {
  table,
  th,
  td {
    border: 1px solid;
    border-collapse: collapse;
    border-color: #05050566;
    text-align: left;
  }
  table th,
  table td {
    padding: 0.4rem 0.6rem;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.list-inside-modal {
  max-height: calc(100vh - 150px);
}
#webpack-dev-server-client-overlay-div {
  display: none !important;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}
