.custom-modal {
  .ant-modal-content {
    border-radius: 24px;
    padding: 32px;
  }
  .ant-modal-body {
    padding: 0;
  }
}
.ant-form-item {
  margin-bottom: 1rem;
  .ant-form-item-label {
    text-align: left;
  }
}
form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
  display: none;
}

.ant-list-items-wrapper {
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 400px;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, #1b8af1, #b58ded);
    background-size: 100% 400px;
    position: absolute;
    z-index: 0;
  }
}

.home .ant-list .ant-list-items {
  padding: 0 10px;

  .ant-list-item {
    position: relative;
    z-index: 1;
    background: transparent;
  }
}

.modal-progress-list.ant-modal {
  width: 100% !important;
  max-width: 100%;
  transform: none;
  top: 0;
  left: 0;
  .ant-modal-content {
    border-radius: 0;
    background: #deeeff;
    min-height: 100vh;
  }
  .ant-modal-title {
    margin: -20px -24px 0;
    padding: 20px 24px;
    width: calc(100% + 48px);
    background: #fff;
    text-align: center;
  }
  .modal-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin: -20px -24px 20px;
    padding: 20px 24px 12px;
    width: 100%;
    background: #fff;
  }
}

.text-white.ant-btn-default:not(:disabled):hover {
  color: #fff;
}

.modal-progress-connect.ant-modal .ant-modal-close {
  left: 20px;
  top: 24px;
  right: 0;
  svg,
  .ant-modal-close-x {
    width: 18px;
    height: 18px;
  }
  span {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}